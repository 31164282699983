// Trackear Eventos
$(document).ready(function(){
  $('.js-click-event').on('click', function(){
  sendGAEvent('click', $(this).data('category'), $(this).data('label'));
  });

});

function sendGAEvent(action, category, label){
  if(typeof gtag !== 'undefined'){
      console.log(action+'-'+category+'-'+label);
      gtag('event', action, { 'event_category': category, 'event_label': label });
  }
}

$('#select-consorcio-versao').on('change', function(){
    window.location = root_rel + 'consorcio/' + $(this).val();
  });

  $(".open-menu").click(function(e){
    $(this).toggleClass("open");
  });

  //Gravar cidade
  $(".js-select-loja").on('change', function(){
    $.get(
            root_rel + 'request/cidade/',
            {"cidade":$(this).val()},
            function(data){
                console.log(data);
                if(data.success){
                    document.location.reload();
                }else{
                    alertify.alert('Atenção', '<p>Ocorreu um erro ao alterar a cidade</p>');
                }
            },
            'json'
        )
});

  //carousel extras ofertas
  function nextExtra(){
    var extras = $(".extras");
    extras.each(function(){
      var items = $(this).children();
      var idx = $(this).find(".ativo").index();
      var max = items.length;
      console.log(idx);
      console.log(max);

      if(max > 1){
        $(".extras .ativo").removeClass("ativo");
        if(idx+1 == max){
          items.eq(0).addClass("ativo");
        }else{
          items.eq(idx+1).addClass("ativo");
        }
      }
    });
  }
  var interval = window.setInterval(nextExtra, 2*1000);

  //Mapa dinamico
  $('.lojas-mapa .js-showmap').on('click', function(){
    $('.js-showmap').removeClass('active');
    $(this).addClass('active');
    id = $(this).data('map_id');
    $('.map-content').removeClass('active');
    $('.map-content.map-content-'+id).addClass('active');
    return false;
});

  $('.click-condicoes').on('click', function(e){
    e.preventDefault();
    alertify.alert( $(this).data('condicoes') ) ;
  });
  $('.js-click-modal-open, a[rel="modal:open"]').on('click', function(){
    var modal_id = $(this).attr('href');

    if(!$(modal_id).length){
      return true;
    }

    var assunto = $(this).data('id_assunto');
    var id_carro = $(this).data('id_carro');
    var id_carro_versao = $(this).data('id_carro_versao');
    var id_pecaacessorio = $(this).data('id_pecaacessorio');
    var interesse = $(this).data('interesse');
    var title = $(this).data('titulo');
    var id_banner = $(this).data('id_banner');
    var id_oferta = $(this).data('id_oferta');
    var modelo = $(this).data('modelo');
    var versao = $(this).data('versao');
    var parcelas = $(this).data('parcelas');
    var por = $(this).data('por');

    if(id_banner && id_banner == 42){
      $(modal_id).find("#cpf-holder").hide();
      $(modal_id).find("#cnpj-holder").show();
    }else{
      $(modal_id).find("#cnpj-holder").hide();
      $(modal_id).find("#cpf-holder").show();
    }

    if(assunto && $(modal_id).find('#form-id_assunto').length){
      $(modal_id).find('#form-id_assunto').val(assunto);
    }
    if(modelo && $(modal_id).find('#form-modelo').length){
      $(modal_id).find('#form-modelo').val(modelo);
    }
    if(versao && $(modal_id).find('#form-versao').length){
      $(modal_id).find('#form-versao').val(versao);
    }
    if(parcelas && $(modal_id).find('#form-parcelas').length){
      $(modal_id).find('#form-parcelas').val(parcelas);
    }
    if(por && $(modal_id).find('#form-por').length){
      $(modal_id).find('#form-por').val(por);
    }
    if(id_carro && $(modal_id).find('#form-id_carro').length){
      $(modal_id).find('#form-id_carro').val(id_carro);
    }
    if(id_carro_versao && $(modal_id).find('#form-id_carro_versao').length){
      $(modal_id).find('#form-id_carro_versao').val(id_carro_versao);
    }
    if(id_pecaacessorio && $(modal_id).find('#form-id_pecaacessorio').length){
      $(modal_id).find('#form-id_pecaacessorio').val(id_pecaacessorio);
    }

    if(interesse && $(modal_id).find('#form-interesse').length){
      $(modal_id).find('#form-interesse').val(interesse);
    }
    if(title && $(modal_id).find('.modal-title').length){
      $(modal_id).find('.modal-title').html(title);
    }



  });

// Slider

// $('.slider-principal').slick({
//  slidesToShow: 1,
//  slidesToScroll: 1,
//  arrows: true,
//  dots: true,
//  prevArrow: $('.seta-esquerda'),
//  nextArrow: $('.seta-direita'),
//});

$('.slider-desktop').on('init', function (slick) {
    $('.slick-prev.slick-arrow.seta-esquerda:before, .slick-prev.slick-arrow.seta-direita:before').css('content', 'none');
    $('.slick-prev.slick-arrow').addClass('seta-esquerda').html('<img class="slide-left" src="" alt="Slide left"  />');//('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $('.slick-next.slick-arrow').addClass('seta-direita').html('<img class="slide-right" src="" alt="Slide right"  />');//('<i class="fa fa-angle-right" aria-hidden="true"></i>');
});
$("body").on('init', '.slider-desktop', function () {
  $('.slider-desktop').css('visibility', 'visible');
});
$('.slider-desktop').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    adaptativeHeight: true
});
$("body").on('init', '.slider-mobile', function () {
  $('.slider-mobile').css('visibility', 'visible');
});
$('.slider-mobile').slick({
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    adaptiveHeight: true
});

 // Seleção Carros

 $('.slider-de-carros').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  lazyLoad: 'ondemand',
  prevArrow: $('.seta-slider-de-carros-esquerda'),
  nextArrow: $('.seta-slider-de-carros-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

 // Seleção Ofertas

 $('.slider-ofertas').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  lazyLoad: 'ondemand',
  prevArrow: $('.seta-slider-de-ofertas-esquerda'),
  nextArrow: $('.seta-slider-de-ofertas-direita'),
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


// Slider Simule Seu Carro

 $('.slider-simule').slick({
  infinite: true,
  speed: 300,
  lazyLoad: 'ondemand',
  prevArrow: $('.seta-slider-simule-esquerda'),
  nextArrow: $('.seta-slider-simule-direita'),
  arrows: false,
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


 // Slider De Peças

 $('.slider-peca').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  lazyLoad: 'ondemand',
  prevArrow: $('.seta-peca-esquerda'),
  nextArrow: $('.seta-peca-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

$('.slider-peca-destaque').slick({
 dots: false,
 infinite: true,
 speed: 300,
 arrows: false,
 lazyLoad: 'ondemand',
 prevArrow: $('.seta-peca-esquerda'),
 nextArrow: $('.seta-peca-direita'),
 slidesToShow: 1,
 slidesToScroll: 1,
 responsive: [
   {
     breakpoint: 1024,
     settings: {
       slidesToShow: 1,
       slidesToScroll: 1,
       infinite: true,
       arrows: false,
       dots: false
     }
   },
   {
     breakpoint: 600,
     settings: {
       slidesToShow: 1,
       slidesToScroll: 1
     }
   },
   {
     breakpoint: 480,
     settings: {
       slidesToShow: 1,
       slidesToScroll: 1,
       dots: false
     }
   }
   // You can unslick at a given breakpoint now by adding:
   // settings: "unslick"
   // instead of a settings object
 ]
});


 // Slider De Seminovos

 $('.slider-seminovos').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  lazyLoad: 'ondemand',
  prevArrow: $('.seta-semi-esquerda'),
  nextArrow: $('.seta-semi-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});



 // Slide Adicionais

 $('.slide-adicionais').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-adicionais-esquerda'),
  nextArrow: $('.seta-adicionais-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});


$(".botao-mais-infos").click(function(){
  $(this).parent().children(".box-mais-info").slideToggle();
  $(this).toggleClass("mais-ativo");
});


$(".adicionais-do-carro, .ver-itens-adicional").click(function(){
  $(this).parent().children(".adicionais-do-carro").slideToggle(function(){
    if( $(this).css('display') == 'block'){
      $(this).parent('.card-adicional').addClass('adicional-ativo');
    } else{
      $(this).parent('.card-adicional').removeClass('adicional-ativo');
    }
  });
});

//Mobile Menu

$('.menu-overlay').on('click', function(){
    closeMenu();
    history.back();
});
$(".btn-menu-mobile").click(function () {
    if ($(this).hasClass('open')) {
        closeMenu();
        history.back();
    } else {
        openMenu();
        window.history.pushState({menu: 'open'}, "Menu", "#menu");
    }
});

$(".open-menu").click(function(e){
  e.stopPropagation();
  $sub = $(this).children(".sub-menu");
  if($sub.hasClass("open")){
    $sub.removeClass("open");
    $sub.slideUp();
  }else{
    $(".menu-top .sub-menu").removeClass("open");
    $(".menu-top .sub-menu").slideUp();

    $sub.addClass("open");
    $sub.slideDown();
  }
});

window.onpopstate = function (event) {
    if ($('.btn-menu-mobile').hasClass('open')) {
        //console.log(event);
        //closeMenu();
    }
};


function openMenu(){
    $(".btn-menu-mobile").addClass('open');
    $(".menu-top").addClass("menu-show");
    $("body").addClass("block-overflow");
    $(".menu-overlay").addClass("show");
}

function closeMenu(){
    $(".btn-menu-mobile").removeClass('open');
    $(".menu-top").removeClass("menu-show");
    $("body").removeClass("block-overflow");
    $(".menu-overlay").removeClass("show");
}

// Slide Versoes

$('.versao').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-versao-esquerda'),
  nextArrow: $('.seta-versao-direita'),
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

//Interna carro
$('.fotos-do-carro').on('init', function(slick){
  $('.slick-prev.slick-arrow').addClass('seta-esquerda').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
  $('.slick-next.slick-arrow').addClass('seta-direita').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
});
$('.fotos-do-carro').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  fade: false,
  asNavFor: '.galeria-thumb',
  adaptiveHeight: true
});

$('.galeria-thumb').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.fotos-do-carro',
  arrows: false,
  dots: false,
  centerMode: false,
  focusOnSelect: true,
  verticalSwiping: true
});

// Slide Outro

$('.outro-slider').slick({
  dots: true,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-outro-esquerda'),
  nextArrow: $('.seta-outro-direita'),
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});

// Ultimo Slider

$('.ultimo-slider').slick({
  dots: false,
  infinite: true,
  speed: 300,
  arrows: true,
  prevArrow: $('.seta-ultimo-esquerda'),
  nextArrow: $('.seta-ultimo-direita'),
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
});




$('.interior').click(function(){
  $('.galeria-fotos').slick('slickPause');
  $(this).addClass('ativo-in-ex');
  $('.exterior').removeClass('ativo-in-ex');
  $('.fotos-interior').removeClass('esconder-imagem');
  $('.fotos-exterior').addClass('esconder-imagem');
  $('.fotos-interior').fadeIn();
  $('.fotos-interior .galeria-fotos').slick('slickPlay');
});

$('.exterior').click(function(){
  $('.galeria-fotos').slick('slickPause');
  $(this).addClass('ativo-in-ex');
  $('.interior').removeClass('ativo-in-ex');
  $('.fotos-exterior').removeClass('esconder-imagem');
  $('.fotos-interior').addClass('esconder-imagem');
  $('.fotos-exterior').fadeIn();
  $('.fotos-exterior .galeria-fotos').slick('slickPlay');

});



// Slider Galeria

 $('.galeria-fotos-exterior').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed:300,
  arrows:true,
  prevArrow: $('.seta-exterior-esquerda'),
  nextArrow: $('.seta-exterior-direita'),
  lazyLoad: 'ondemand',
  fade: false,
  responsive: [{
    breakpoint: 767,
    settings:{
      slidesToShow:1,
      slidesToScroll:1,
      infinite:true,
    }
  }]
});

 $('.galeria-fotos-interna').slick({
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  speed:300,
  arrows:true,
  prevArrow: $('.seta-interior-esquerda'),
  nextArrow: $('.seta-interior-direita'),
  lazyLoad: 'ondemand',
  fade: false,
  responsive: [{
    breakpoint: 767,
    settings:{
      slidesToShow:1,
      slidesToScroll:1,
      infinite:true,
    }
  }]
});
$('.galeria-quem-somos').slick({
 slidesToShow: 2,
 slidesToScroll: 1,
 arrows: 1,
 fade: false,
 lazyLoad: 'ondemand',
 responsive: [{
   breakpoint: 767,
   settings:{
     slidesToShow:1,
     slidesToScroll:1,
     infinite:true,
   }
 }]
});

 $(".fotos-interior").hide();
/*$('.pager-galeria').slick({
  slidesToShow: 8,
  slidesToScroll: 8,
  asNavFor: '.galeria-fotos',
  dots: false,
  arrows: false,
  focusOnSelect: true
});*/

$('.form-ajax').ajaxForm({
  dataType: 'json',
  beforeSubmit: function (arr, $form, options) {
    $('.submit-action').attr('disabled', 'disabled');
    startLoading();
  },
  success: function (json, status, xhr, form) {
    var id_form = form[0].id;

    if (json.erro != "") {
      alertify.alert(json.erro);
      sendGAEvent('generate_lead', 'lead_error',  json.erro);
    } else {
      alertify.alert(json.sucesso);
      $('#' + id_form)[0].reset();
      sendGAEvent('generate_lead', 'lead', id_form);
    }
  },
  error: function () {
    alertify.alert("Erro ao processar formulário.<br>Tente novamente mais tarde.");
  },
  complete: function () {
    $('.submit-action').removeAttr('disabled');
    stopLoading();
  }
});

//loading
function startLoading(){
  $('.loading-wrapper').stop(true, true).fadeIn();
}

function stopLoading(){
  $('.loading-wrapper').stop(true, true).fadeOut('fast');
}

$(".mask--ano").mask("9999");
$(".mask--data").mask("99/99/9999");
$(".mask--cep").mask("99999-999");
$(".mask--cpf").mask("999.999.999-99");
$(".mask--cnpj").mask("99.999.999/9999-99");
$(".mask--placa").mask("aaa-9999");
$('.mask--telefone')
  .mask("(99)9999-9999?9", { placeholder: "" })
  .blur(function (event) {
    var target, phone, element;
    target = (event.currentTarget) ? event.currentTarget : event.srcElement;
    phone = target.value.replace(/\D/g, '');
    element = $(target);
    element.unmask();
    if(phone.length > 10) {
      element.mask("(99)99999-999?9", { placeholder: "" });
    } else {
      element.mask("(99)9999-9999?9", { placeholder: "" });
    }
  });

$('.mask--money').maskMoney({
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  allowZero: true,
  allowEmpty: true,
});

$('.clique-menu').click(function(){
  $('.box-menu-mobile').slideDown('slow');
});

$('.clique-fechar').click(function(){
  $('.box-menu-mobile').slideUp();
});

$('.carro-sub-menu').click(function(){
  $('.sub-carro').slideToggle('slow');
  $('.sub-pos').slideUp('slow');
});

$('.menu-mobile li').click(function(){
  $('.sub-menu').slideUp('slow');
  if($(this).hasClass("open")){
    $(this).removeClass("open");
  }else{
    $(this).children('.sub-menu').slideDown('slow');
    $(this).addClass("open");
  }
});

var initialText = $('.other').val();
$('.editOption').val(initialText);

$('#veiculo').change(function(){
  var selected = $('option:selected', this).attr('class');
  var optionText = $('.other').text();

  if(selected == "other"){
    $('.editOption').show();


    $('.editOption').keyup(function(){
        var editText = $('.editOption').val();
        $('.other').val(editText);
        $('.other').html(editText);
    });

  }else{
    $('.editOption').hide();
  }
});

  //Estoque

  $(".btn-mais-filtro").click(function(){
    if($(this).hasClass("mais")){
      $(this).removeClass("mais");
      $(this).addClass("menos");
      $(this).children("span").html("Menos filtros");
    }else{
      $(this).addClass("mais");
      $(this).removeClass("menos");
      $(this).children("span").html("Mais filtros");
    }
  });

  $('body').on('change', '.select-marca', function() {
    console.log("marca 1");
    var id_marca = $(this).val();
    var $this = $(this);
    carregarModelos(id_marca, $this);
  });

  if($('.select-marca').length > 0) {
    $('.select-marca').each(function(index) {
      console.log("marca 2");
      var id_marca = $(this).val();
      var $this = $(this);
      if($this.closest('form').find('.select-modelo').children().length <= 1){
        carregarModelos(id_marca, $this);
      }
    });
  }

  $('.limit_refresh').on('change', function () {
    refreshPage($(this).attr('name'), $(this).val());
  });

  function refreshPage(atributo, valor){
    var hash = {};
    var parser = document.createElement('a');

    parser.href = window.location.href;
    var parameters = parser.search.split(/\?|&/);
    for(var i=0; i < parameters.length; i++) {
        if(!parameters[i])
            continue;

        var ary      = parameters[i].split('=');
        hash[ary[0]] = ary[1];
    }

    hash[atributo] = valor;

    var list = [];
    Object.keys(hash).forEach(function (key) {
        list.push(key + '=' + hash[key]);
    });

    parser.search = '?' + list.join('&');
    window.location.href = parser.href;
  }


  function carregarModelos(id_marca, $this){
    if(id_marca != "" && id_marca > 0){
        $.ajax({
            url: root_rel + 'lua4auto/seminovosmodelos/busca/'+id_marca,
            type: 'get',
            beforeSend: function(){
                $this.closest('form').find('.select-modelo').html('<option value="0">Selecione primeiro uma marca</option>');
                startLoading();
            },
            dataType: 'json',
            success: function(json){
                var html = '<option value="">Selecione um modelo</option>';
                $.each(json, function(key, value) {
                    if($this.hasClass('marca-busca')){
                        if(value.qtd_carros > 0){
                            html += '<option value="'+value.id_modelo+'">'+value.descricao+'</option>';
                        }
                    }else{
                        html += '<option value="'+value.id_modelo+'">'+value.descricao+'</option>';
                    }
                });
                $this.closest('form').find('.select-modelo').html(html);
                $this.closest('form').find('.select-modelo').attr('disabled', false);
            },
            complete: function(){
                stopLoading();
            }
        });
    }
  }

  if($('.car-right-box').length > 0){
    $(window).scroll(function() {
      var vpWidth = $(document).width();
      if (vpWidth > 1200) {
            var winTop = $(window).scrollTop();
        var pageContentTop = $('.box-interna-carro').offset().top - 120;

        var mainH = $('.box-interna-carro').height();
        var sidebarH = $('.car-right-box').height();

        var mainSideDiff = mainH - sidebarH +20; // 20 = main margin-bottom
        var stopPointBottom = pageContentTop + (mainSideDiff);

        if(winTop > pageContentTop && winTop < stopPointBottom) {
          var diff = winTop - pageContentTop;
          $('.car-right-box').css('top', diff + 15); // 10 = to give some space from top
        } else if(winTop > stopPointBottom) {
          $('.car-right-box').css('top', mainSideDiff);
        } else {
          $('.car-right-box').css('top', 0);
        }
      }
    });
  }

var anoveiculo = document.getElementById('anoveiculo');
var kmveiculo = document.getElementById('kmveiculo');
var valorveiculo = document.getElementById('valorveiculo');
if($(anoveiculo).length){
    noUiSlider.create(anoveiculo, {
        range: {
            'min': anoveiculoMin,
            'max': anoveiculoMax
        },
        step: 1,
        start: [anoveiculoMin, anoveiculoMax],
        margin: 0,
        connect: true,
        format: {
              to: function ( value ) {
                return parseInt(value);
              },
              from: function ( value ) {
                return parseInt(value);
              }
            },
        behaviour: 'tap-drag',
    //    tooltips: true
    });
    anoveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-ano_' + handle).val(values[handle]);
        $('#slider-ano-range').html(values.join(' até '));
    });
}
if($(kmveiculo).length){
    noUiSlider.create(kmveiculo, {
        range: {
            'min': kmMin,
            'max': kmMax
        },
        step: 5000,
        start: [kmMin, kmMax],
        margin: 0,
        connect: true,
        format: {
              to: function ( value ) {
                return parseInt(value);
              },
              from: function ( value ) {
                return parseInt(value);
              }
            },
        behaviour: 'tap-drag',
    //    tooltips: true
    });
    kmveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-km_' + handle).val(values[handle]);
        values[0] = values[0].toLocaleString("pt-BR");
        if(values[1] == kmMax){
            values[1] = kmMaxLabel;
        }else{
            values[1] = values[1].toLocaleString("pt-BR");
        }
        $('#slider-km-range').html(values.join(' até '));
    });

}

if($(valorveiculo).length){
    noUiSlider.create(valorveiculo, {
        range: {
            'min': [valorMin],
            '70%': [70000],
            'max': [valorMax]
        },
        step: 5000,

        start: [valorMin, valorMax],
        connect: true,

        format: {
            to: function ( value ) {
              return parseInt(value);
            },
            from: function ( value ) {
              return parseInt(value);
            }
          },

        behaviour: 'tap-drag',
    //    tooltips: true
    });
    valorveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-valor_' + handle).val(values[handle]);
         values[0] = "R$ " + values[0].toLocaleString("pt-BR");
         if(values[1] == valorMax){
            values[1] = valorMaxLabel;
        }else{
            values[1] = "R$ " + values[1].toLocaleString("pt-BR");
        }
        $('#slider-valor-range').html(values.join(' até '));
    });
}
